import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import './card.scss'

function Card(props) {

  return (
    <Link className="cardBlock" to={props.url}>
      <div className="cardBlock__image">
        <GatsbyImage image={props.imgSrc} alt={props.name}/>
      </div>
      <div className="cardBlock__info">
        <h3>{props.name}</h3>
        <div className="description">
          <p><span>Цена:</span> {props.cost}</p>
          <p><span>Cроки:</span> {props.time}</p>
        </div>
        <div className="cardBlock__order">
          <p>Оформить заказ</p>
        </div>
      </div>
    </Link>
  )
}

export default Card
