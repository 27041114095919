import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from 'gatsby-plugin-image'

import '../styles/style.scss'

import Seo from '../component/Seo'
import Layout from '../templates/Layout'
import Navigation from '../component/navigation/Navigation'
import Card from '../component/servicePage/card/Card'
import Footer from '../component/mainPage/callbackSection/CallbackSection'

function Design() {
  const data = useStaticQuery(graphql`
  {
    allDesignDataJson {
      nodes {
        id
        name
        cost
        time
        url
        image {
          childImageSharp {
            gatsbyImageData(
              width: 250
              formats: [AUTO, AVIF, WEBP]
              placeholder: TRACED_SVG
              quality: 85
            )
          }
        }
      }
    }
  }
`)

  return (
    <div className="servicePage">
    <Seo title="Дизайн и печать" />
    <Navigation />
    <Layout styleName="serviceContent">
      <h1 className="titleBlue">Дизайн и печать</h1>
      <div className="cardsList">
        {data.allDesignDataJson.nodes.map(designInfo => {
          const { name, cost, time, image, id, url } = designInfo;
          const imgSrc = getImage(image)

          return <Card key={id} name={name} cost={cost} time={time} imgSrc={imgSrc} url={url}/>
        })}
      </div>
    </Layout>
    <Footer />
    </div>
  )
}

export default Design